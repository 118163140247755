import React from "react"

import Layout from "../components/layout/ca"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <SEO title="404: No trobada" />
    <h1>PÀGINA NO TROBADA</h1>
    <p>Has intentat navegar en una ruta que no existeix.</p>
  </Layout>
)

export default NotFoundPage
